import React from "react"
import SEO from "../components/seo"
import Header from "../components/header"

const IndexPage = () => (
  <>
    <SEO title={"Eightyeightbits"} titleTemplate={`%s`} />
    <div id={'main'} >
      <section className="hero is-link is-fullheight">
        <div className="hero-head">
          <Header />
        </div>

        <div className="hero-body">
          <div className="container has-text-centered">
            <p className="title">
              Eightyeightbits
            </p>

            <div className="subtitle-container">
              <span className="subtitle">Development</span>
              <span className="subtitle">Design</span>
              <span className="subtitle">Automation</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  </>
)

export default IndexPage
